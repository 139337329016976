export default class Title {

  constructor() {
    this.wrapper = document.querySelector('.js--title');
    this.animate();
  }

  animate() {
    this.wrapper.classList.remove('min-h-[100vh]');

    // this.wrapper.classList.remove('-mb-[30vh]');
    // this.wrapper.classList.add('min-h-[70vh]');

    // this.wrapper.classList.remove('md:-mb-[20vh]');
    // this.wrapper.classList.add('md:min-h-[80vh]');

    this.wrapper.classList.remove('-mb-[20vh]');
    this.wrapper.classList.add('min-h-[80vh]');
  }

}
// import '../fonts/fontimport.js'
// import '../images/imageimport.js'

// import Loader from "./components/loader";
// import Header from "./components/header";
// import HeaderTicker from "./components/header-ticker";
import BarbaJs from "./components/barba-js";

// https://kenwheeler.github.io/slick/

// new Loader('.js--loader');
// new Header('.js--header');
// new HeaderTicker('.js--header-ticker');
new BarbaJs();

document.documentElement.classList.replace('no-js', 'js');
export default class Projects {

  constructor() {
    const projects_target = document.querySelector('#projects-list');
    if (!projects_target) { return }

    let projects_wrapper = document.querySelector('#projects-list');
    let filter_wrapper = document.querySelector('#projects-filter');
    const more_button = document.querySelector('#projects-load-more');
    const filter_links = document.querySelectorAll('.projects-filter-link');

    const fetchMoreProjects = async () => {
      let next_page = parseInt(projects_target.getAttribute('data-next-page'));
      const matches = window.location.pathname.match(/services:([^/]*)/g);
      let url = '';
      if (matches && matches.length) {
        url = `/projekte.json/${matches[0]}/page:${next_page}`;
      }
      else {
        url = `/projekte.json/page:${next_page }`;
      }
      try {
        const response = await fetch(url);
        const { html, more, next_page } = await response.json();

        const last_child = projects_target.children[projects_target.children.length- 1];

        projects_target.insertAdjacentHTML('beforeend', html);
        projects_target.dataset.nextPage = next_page;

        if (last_child.nextElementSibling) {
          last_child.nextElementSibling.scrollIntoView({
            behavior: 'smooth',
          });
        }

        more_button.classList.toggle('opacity-0', !more);
        more_button.classList.toggle('invisible', !more);
        more_button.classList.toggle('opacity-100', more);
        more_button.classList.toggle('visible', more);

        setTimeout(() => {
          projects_wrapper.classList.remove('opacity-0');
        }, 200);
      } catch (error) {
        console.log('Fetch error: ', error);
      }
    }

    more_button.addEventListener('click', () => {
      projects_wrapper.classList.add('opacity-0');
      setTimeout(() => {
        fetchMoreProjects()
      }, 200);
    });


    const replaceProjects = async (filter_link) => {
      try {
        const response = await fetch(filter_link.href.replace('/projekte', '/projekte.json'));
        const { html, more, next_page, filter_urls } = await response.json();

        // update url
        window.history.pushState('projects', 'Projekte', filter_link.href);

        // update button state
        filter_link.classList.toggle('active')

        // update filter links
        for (const name in filter_urls) {
          let link = document.querySelector('a[data-name="' + name + '"]');
          if (link) {
            link.href = filter_urls[name];
          }
        }

        // replace projects
        projects_target.innerHTML = '';
        projects_target.insertAdjacentHTML('beforeend', html);
        projects_target.dataset.nextPage = 2

        // scroll to filter
        filter_wrapper.scrollIntoView({
          behavior: 'smooth',
        });

        more_button.classList.toggle('opacity-0', !more);
        more_button.classList.toggle('invisible', !more);
        more_button.classList.toggle('opacity-100', more);
        more_button.classList.toggle('visible', more);

        setTimeout(() => {
          filter_wrapper.classList.remove('opacity-20');
          projects_wrapper.classList.remove('opacity-0');
        }, 200);
      } catch (error) {
        console.log('Fetch error: ', error);
      }
    }

    filter_links.forEach(filter_link => {
      filter_link.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        filter_wrapper.classList.add('opacity-20');
        projects_wrapper.classList.add('opacity-0');
        setTimeout(() => {
          replaceProjects(filter_link);
        }, 200)
      });
    });
  }

}
export default class ServicesMenu {

  constructor() {
    const wrapper = document.querySelector('.js--services-menu');
    if (!wrapper) { return }

    const triggers = wrapper.querySelectorAll('.services-menu-link');

    triggers.forEach(trigger => {
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.scrollTo(trigger.hash);
      });
    });

    if (window.location.hash) {
      setTimeout(() => {
        this.scrollTo(window.location.hash);
      }, 250);
    }
  }

  scrollTo(selector) {
    const target = document.querySelector(selector);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

}
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class StickyHeadline {

  constructor() {
    this.wrappers = document.querySelectorAll('.js--sticky-headline');
    if (!this.wrappers.length) {
      return;
    }

    this.wrappers.forEach(wrapper => {
      this.init(wrapper);
    });
  }


  init(wrapper) {
    const headline = wrapper.querySelector('.sticky-headline');

    ScrollTrigger.create({
      trigger: wrapper,
      start: "top center",
      end: "bottom bottom",
      pin: headline,
      pinSpacing: false
    });

    // title height animation
    window.addEventListener('transitionend', (event) => {
      if (event.propertyName === 'min-height') {
        ScrollTrigger.refresh();
      }
    })
  }

}
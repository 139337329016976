export default class Video {

  constructor(selector) {
    this.wrappers = document.querySelectorAll(selector);
    if (!this.wrappers.length) {
      return;
    }

    for (let index = 0; index < this.wrappers.length; index++) {
      const wrapper = this.wrappers[index];
      const video = wrapper.querySelector('.video--video');
      const loop = wrapper.querySelector('.video--loop');
      const playTrigger = wrapper.querySelector('.video--play-trigger');
      const playTriggerLabel = wrapper.querySelector('.video--play-trigger-label');
      const soundTrigger = wrapper.querySelector('.video--sound-trigger');

      if (playTrigger) {
        playTrigger.addEventListener('click', (event) => {
          event.preventDefault();
          if (video.paused) {
            video.play();
          }
          else {
            video.pause();
          }
          loop.pause();
        });
      }


      // soundtrigger toggles sound only
      if (playTrigger && soundTrigger) {
        soundTrigger.addEventListener('click', (event) => {
          event.preventDefault();
          video.muted = !video.muted
        });

        video.addEventListener('volumechange', (event) => {
          if (soundTrigger) {
            soundTrigger.classList.toggle('line-through', video.muted)
          }
        });

        video.addEventListener('play', (event) => {
          if (soundTrigger) {
            soundTrigger.classList.remove('opacity-0');
            soundTrigger.classList.remove('invisible');
          }
        });

        video.addEventListener('pause', (event) => {
          if (soundTrigger) {
            soundTrigger.classList.add('opacity-0')
            soundTrigger.classList.add('invisible');
          }
        });
      }


      // soundtrigger toggles loop and video
      if (!playTrigger && soundTrigger) {
        video.setAttribute('loop', 'loop');
        let videoPlayed = false;

        soundTrigger.addEventListener('click', (event) => {
          event.preventDefault();

          if (videoPlayed) {
            video.muted = !video.muted;
          }
          else {
            if (!loop.paused) {
              loop.pause();
              video.muted = false;
              video.currentTime = loop.currentTime;
              video.play();
              videoPlayed = true;
            }
            else {
              video.pause();
              loop.currentTime = video.currentTime;
              loop.play();
            }
          }

          soundTrigger.classList.toggle('line-through', video.muted)
        });
      }


      if (wrapper) {
        wrapper.addEventListener('play', (event) => {
          if (event.target == video) {
            setTimeout(() => {
              wrapper.classList.add('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Stop';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('pause', (event) => {
          if (event.target == video) {
            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('ended', (event) => {
          if (event.target == video) {
            video.currentTime = 0;
            video.load();
            loop.play();

            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);
      }

      if (loop) {
        loop.play();
      }

    }
  }


}

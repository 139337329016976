export default class Scrollup {

  constructor() {
    const trigger = document.querySelector('.js--scrollup');
    if (!trigger) { return }

    trigger.addEventListener('click', (event) => {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }

}
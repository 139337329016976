export default class Scrolling {

  constructor(selector) {
    this.body = document.querySelector('body');

    document.addEventListener('scroll', (event) => {
      this.body.classList.add('scrolling');
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {this.body.classList.remove('scrolling');} , 500);

      this.body.classList.toggle('scrolled', window.scrollY > window.innerHeight);
    });

  }

}
import { gsap } from "gsap";

export default class FooterButton {

  constructor() {
    if (this.isTouchDevice()) {
      return;
    }

    this.button = document.querySelector('.footer--button');
    this.deco = document.querySelector('.footer--button-deco');

    this.locked = true;
    this.tlSize = gsap.timeline();
    this.tlPos = gsap.timeline();

    this.init();
  }


  init() {
    this.button.addEventListener('mouseenter', (event) => this.mouseEnterHandler(event));
    this.button.addEventListener('mousemove', (event) => this.mouseMoveHandler(event));
    this.button.addEventListener('mouseleave', (event) => this.mouseLeaveHandler(event));
  }

  mouseEnterHandler(event) {
    this.locked = true;

    this.tlPos.kill();
    let buttonRect = this.button.getBoundingClientRect();
    this.button.style.setProperty('--circle-x', event.pageX - buttonRect.left + 'px'),
    this.button.style.setProperty('--circle-y', event.pageY - buttonRect.top - window.scrollY + 'px'),

    this.tlSize.clear();
    this.tlSize.to(this.button, {
      duration: .55,
      '--circle-radius': '40%',
      ease: "expo",
      onComplete: () => {
        this.locked = false;
      }
    });
  }

  mouseMoveHandler(event) {
    let buttonRect = this.button.getBoundingClientRect();
    this.tlPos.clear();
    this.tlPos.to(this.button, {
      duration: this.locked ? .45 : .25,
      '--circle-x': event.pageX - buttonRect.left + 'px',
      '--circle-y': event.pageY - buttonRect.top - window.scrollY + 'px',
    });

    if (!this.locked) {
      let grow = Math.min(Math.max(Math.abs(event.movementX) + Math.abs(event.movementY) / this.button.clientWidth, 0), 15);
      this.tlSize.clear();
      this.tlSize.to(this.button, {
        duration: 0.8,
        '--circle-radius': 40 + grow + '%',
        ease: "expo",
      });
    }
  }

  mouseLeaveHandler(event) {
    this.locked = true;

    this.tlSize.clear();
    this.tlSize.to(this.button, {
      duration: .55,
      '--circle-radius': '0',
      ease: "expo",
    });

    let buttonRect = this.button.getBoundingClientRect();
    this.tlPos.clear();
    this.tlPos.to(this.button, {
      duration: .65,
      '--circle-x': event.pageX - buttonRect.left + 'px',
      '--circle-y': (event.pageY - buttonRect.top - window.scrollY) + 'px',
      ease: "expo",
      onComplete: () => {
        this.locked = false;
      }
    });
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

}